import axios from '../http'

export function login(data) {
    return axios.post('login', data)
}

export function editUser(data) {
    data.workType=data.workType.toString()
    data.certificate=data.certificate.toString()
    return axios.post('sysUser/edit', data)
}

export function editUserB(data) {
    data.workType=data.workType?data.workType.toString():''
    data.certificate=data.certificate?data.certificate.toString():''
    return axios.post('sysUser/editb', data)
}

/**
 * 普通用户完善信息
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function editUserf(data) {
    data.workType=data.workType.toString()
    data.certificate=data.certificate?data.certificate.toString():null
    return axios.post('sysUser/edit/f', data)
}

export function upload(data) {
    return axios.post('storage/create', data, {
        headers: {
            "content-type": "multipart/form-data",
        },
    })
}

/**
 * 获取当前用户信息
 * @returns 
 */
export function me() {
    return axios.get('sysUser')
}